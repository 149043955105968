exports.components = {
  "component---src-build-cloud-js": () => import("./../../../src/build/cloud.js" /* webpackChunkName: "component---src-build-cloud-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-documentation-getting-started-js": () => import("./../../../src/pages/documentation/getting_started.js" /* webpackChunkName: "component---src-pages-documentation-getting-started-js" */),
  "component---src-pages-documentation-guides-and-tutorials-albert-finetuning-js": () => import("./../../../src/pages/documentation/guides_and_tutorials/albert_finetuning.js" /* webpackChunkName: "component---src-pages-documentation-guides-and-tutorials-albert-finetuning-js" */),
  "component---src-pages-documentation-guides-and-tutorials-chartjs-bar-js": () => import("./../../../src/pages/documentation/guides_and_tutorials/chartjs_bar.js" /* webpackChunkName: "component---src-pages-documentation-guides-and-tutorials-chartjs-bar-js" */),
  "component---src-pages-documentation-guides-and-tutorials-chartjs-line-js": () => import("./../../../src/pages/documentation/guides_and_tutorials/chartjs_line.js" /* webpackChunkName: "component---src-pages-documentation-guides-and-tutorials-chartjs-line-js" */),
  "component---src-pages-documentation-guides-and-tutorials-danfo-js": () => import("./../../../src/pages/documentation/guides_and_tutorials/danfo.js" /* webpackChunkName: "component---src-pages-documentation-guides-and-tutorials-danfo-js" */),
  "component---src-pages-documentation-guides-and-tutorials-face-recognition-js": () => import("./../../../src/pages/documentation/guides_and_tutorials/face_recognition.js" /* webpackChunkName: "component---src-pages-documentation-guides-and-tutorials-face-recognition-js" */),
  "component---src-pages-documentation-guides-and-tutorials-index-js": () => import("./../../../src/pages/documentation/guides_and_tutorials/index.js" /* webpackChunkName: "component---src-pages-documentation-guides-and-tutorials-index-js" */),
  "component---src-pages-documentation-guides-and-tutorials-movie-recommendation-js": () => import("./../../../src/pages/documentation/guides_and_tutorials/movie_recommendation.js" /* webpackChunkName: "component---src-pages-documentation-guides-and-tutorials-movie-recommendation-js" */),
  "component---src-pages-documentation-guides-and-tutorials-plotly-getting-started-js": () => import("./../../../src/pages/documentation/guides_and_tutorials/plotly_getting_started.js" /* webpackChunkName: "component---src-pages-documentation-guides-and-tutorials-plotly-getting-started-js" */),
  "component---src-pages-documentation-guides-and-tutorials-regression-training-js": () => import("./../../../src/pages/documentation/guides_and_tutorials/regression_training.js" /* webpackChunkName: "component---src-pages-documentation-guides-and-tutorials-regression-training-js" */),
  "component---src-pages-documentation-guides-and-tutorials-transfer-learning-mobilenet-js": () => import("./../../../src/pages/documentation/guides_and_tutorials/transfer_learning_mobilenet.js" /* webpackChunkName: "component---src-pages-documentation-guides-and-tutorials-transfer-learning-mobilenet-js" */),
  "component---src-pages-documentation-index-js": () => import("./../../../src/pages/documentation/index.js" /* webpackChunkName: "component---src-pages-documentation-index-js" */),
  "component---src-pages-documentation-interface-js": () => import("./../../../src/pages/documentation/interface.js" /* webpackChunkName: "component---src-pages-documentation-interface-js" */),
  "component---src-pages-documentation-javascript-js": () => import("./../../../src/pages/documentation/javascript.js" /* webpackChunkName: "component---src-pages-documentation-javascript-js" */),
  "component---src-pages-email-verification-js": () => import("./../../../src/pages/email-verification.js" /* webpackChunkName: "component---src-pages-email-verification-js" */),
  "component---src-pages-hub-js": () => import("./../../../src/pages/hub.js" /* webpackChunkName: "component---src-pages-hub-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-cancelled-js": () => import("./../../../src/pages/payment_cancelled.js" /* webpackChunkName: "component---src-pages-payment-cancelled-js" */),
  "component---src-pages-payment-successful-js": () => import("./../../../src/pages/payment_successful.js" /* webpackChunkName: "component---src-pages-payment-successful-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */)
}

